import { useState, useContext, createContext } from 'react';
import PropTypes from 'prop-types';

const OnboardingDataContext = createContext(undefined);

export const OnboardingDataProvider = ({ onboardingData, children }) => {
  const [currentOnboardingData, setCurrentOnboardingData] = useState(onboardingData);
  return (
    <OnboardingDataContext.Provider value={{ currentOnboardingData, setCurrentOnboardingData }}>
      { children }
    </OnboardingDataContext.Provider>
  );
};

OnboardingDataProvider.propTypes = {
  onboardingData: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
};
OnboardingDataProvider.defaultProps = {
  onboardingData: undefined,
};

export const useOnboardingData = () => useContext(OnboardingDataContext);
