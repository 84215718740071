import fetch from './api';
import { API_ECOMMERCE, getEnv } from './env';

const urlBackend = getEnv(API_ECOMMERCE);

export const getShoppingCart = (transactionId) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/shoppingcart/${transactionId}`,
    method: 'get',
  });

export const getCustomers = (dni) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/customers/${dni}`,
    method: 'get',
  });

export const validateCellphone = (customerTaxId, cellphone) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/customers/${customerTaxId}/validateCellphone`,
    method: 'get',
    params: { cellphone },
  });

export const updateCustomerData = (customerTaxId, cellphone, email) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/customers/${customerTaxId}/updateCustomerData`,
    method: 'get',
    params: { cellphone, email },
  });

export const sendCode = (customerTaxId, hash, sendByEmail, email, cellphone) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/customers/${customerTaxId}/sendCode`,
    method: 'get',
    params: {
      hash, sendByEmail, email, cellphone,
    },
  });

export const verifyCode = (customerTaxId, code, cellphone) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/customers/${customerTaxId}/verifyCode`,
    method: 'get',
    params: { code, cellphone },
  });

export const startWorkflow = (customerTaxId, cellphone, transactionId, email) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/customers/${customerTaxId}/start`,
    method: 'post',
    data: {
      cellphone,
      transactionId,
      email,
    },
  });
