import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box, Grid, Hidden, Typography,
} from '@material-ui/core';
import { useMediaQuery } from '@react-hook/media-query';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router';
import LoadingButton from '../components/common/LoadingButton';
import TextInput from '../components/common/TextInput';
import { useOnboardingData } from '../contexts/onboardingDataContext';
import { onboardingSchemaValidation } from '../forms/schemaValidations/schemaValidations';
import { saveCurrentOnboardingData } from '../utils/userStorage';
import { getCustomers } from '../api/ecommerceWebService';
import '../assets/css/onBoardingStyle.css';
import i18n from '../common/i18n';
import { route } from '../constants/routes';
import { moneyFormatter } from '../utils/formatterUtil';
import ErrorMessage from '../components/common/ErrorMessage';
import NavBar from '../components/NavBar';
import HeaderLandingWelcome from '../components/HeaderLandingWelcome';
import GoBackAndLogoDesktop from '../components/GoBackAndLogoDesktop';

const Onboarding = () => {
  const methods = useForm({
    resolver: yupResolver(onboardingSchemaValidation),
  });

  const history = useHistory();
  const isMobile = useMediaQuery('(max-width:600px)');

  const [responseError, setResponseError] = useState(false);
  const [loading, setLoading] = useState(false);

  const { currentOnboardingData, setCurrentOnboardingData } = useOnboardingData();

  const transactionId = new URLSearchParams(window.location.search).get('trx-uuid');

  const location = useLocation();

  const onSubmit = async (formData) => {
    setLoading(true);
    const onboardingData = { ...currentOnboardingData };
    onboardingData.contactData = formData;

    try {
      const result = await getCustomers(formData.dni);
      onboardingData.users = result.data;

      setCurrentOnboardingData(onboardingData);
      saveCurrentOnboardingData(onboardingData);

      const trxUUID = new URLSearchParams({ 'trx-uuid': transactionId }).toString();
      history.push({ pathname: route.cuilValidation, search: `?${trxUUID}` });
    } catch (error) {
      setResponseError(true);
    }
  };

  const goToWelcome = () => {
    const trxUUID = new URLSearchParams({ 'trx-uuid': transactionId }).toString();
    history.push({ pathname: route.welcome, search: `?${trxUUID}` });
  };

  const init = async () => {
    if (transactionId === null) {
      setResponseError(true);
    } else if (currentOnboardingData === null || currentOnboardingData.shoppingCart === undefined) {
      goToWelcome();
    }
  };

  useEffect(() => init(), []);

  return (
    <>
      {responseError === false && currentOnboardingData && (
        <>
          <Hidden smUp>
            <NavBar shoppingCart={currentOnboardingData.shoppingCart} />
          </Hidden>
          <Grid container>
            <Hidden smDown>
              <Grid item xs={12} sm={6}>
                <HeaderLandingWelcome />
              </Grid>
            </Hidden>
            <Grid item container xs={12} sm={6} justifyContent="center">
              { location.state !== undefined && (
                <Grid container className="boxHeader">
                  <Grid item lg={1.5} />
                  <Grid item lg={9} className="gridCart">
                    <Typography
                      variant="p"
                      className="typographyCart"
                    >
                      {i18n.onboardingBuying}
                      <img
                        src={location.state.cart.imgLogo}
                        alt={location.state.cart.commerceName}
                        pl={3}
                        width="135px"
                      />
                      {i18n.onboardingPrice}
                    </Typography>
                    <Typography variant="p" className="typographyCartPrice">
                      <b>
                        {moneyFormatter.format(location.state.cart.price)}
                      </b>
                    </Typography>
                  </Grid>
                </Grid>
              )}
              <Grid item container justifyContent="center">
                <Grid item xs={11} sm={9}>
                  <Hidden xsDown>
                    <GoBackAndLogoDesktop shoppingCart={currentOnboardingData.shoppingCart} />
                    <Box m={5} />
                  </Hidden>
                  <Hidden smUp>
                    <Box m={5} />
                  </Hidden>
                  <Box className="boxStart">
                    <Typography variant="h2" component="h1">
                      {i18n.onboardingTitle}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={11} sm={9}>
                  <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                      <Grid item container spacing={3}>
                        <Grid item sm={12} xs={12}>
                          <TextInput
                            name="dni"
                            label={i18n.onboardingDNILabel}
                            defaultValue={currentOnboardingData.shoppingCart.customerCode}
                            fullWidth
                            helperText={i18n.onboardingDNIHint}
                            inputProps={{ minLength: 6, maxLength: 8 }}
                            mandatory
                            disabled={currentOnboardingData.shoppingCart.customerCode
                              && currentOnboardingData.shoppingCart.disablePreOnboardingInputs}
                          />
                        </Grid>
                        <Grid item sm={12} xs={12}>
                          <TextInput
                            name="email"
                            label={i18n.onboardingEmailLabel}
                            defaultValue={currentOnboardingData.shoppingCart.email}
                            mandatory
                            disabled={currentOnboardingData.shoppingCart.email
                              && currentOnboardingData.shoppingCart.disablePreOnboardingInputs}
                            fullWidth
                          />
                        </Grid>
                        <Grid item sm={12} xs={12}>
                          <TextInput
                            name="cellphone"
                            label={i18n.onboardingCellphoneLabel}
                            defaultValue={currentOnboardingData.shoppingCart.cellphone}
                            mandatory
                            disabled={currentOnboardingData.shoppingCart.cellphone
                              && currentOnboardingData.shoppingCart.disablePreOnboardingInputs}
                            fullWidth
                            helperText={i18n.onboardingCellPhoneHint}
                          />
                        </Grid>
                      </Grid>
                      <Box m={isMobile ? 3 : 7} />
                      <Grid item xs={12}>
                        <LoadingButton
                          variant="contained"
                          color="primary"
                          style={{ width: '100%' }}
                          type="submit"
                          loading={loading}
                        >
                          {i18n.onboardingCTAContinue}
                        </LoadingButton>
                      </Grid>
                    </form>
                  </FormProvider>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}

      {responseError && (<ErrorMessage />)}
    </>
  );
};

export default Onboarding;
