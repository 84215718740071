import {
  BrowserRouter, Switch, Route, Redirect,
} from 'react-router-dom';

import EcommerceLandingWelcome from '../pages/ecommerceLandingWelcome';
import Onboarding from '../pages/onboarding';
import CuilValidation from '../pages/cuilValidation';
import VerificationCodeValidation from '../pages/verificationCodeValidation';
import { OnboardingDataProvider } from '../contexts/onboardingDataContext';
import { getCurrentOnboardingData } from '../utils/userStorage';
import { route } from '../constants/routes';

const AppRouter = () => {
  const onboardingData = getCurrentOnboardingData();

  return (
    <>
      <BrowserRouter>
        <OnboardingDataProvider onboardingData={onboardingData}>
          <Switch>
            <Route exact path={route.welcome}>
              <EcommerceLandingWelcome />
            </Route>
            <Route exact path={route.onboarding}>
              <Onboarding />
            </Route>
            <Route exact path={route.cuilValidation}>
              <CuilValidation />
            </Route>
            <Route exact path={route.verificationCode}>
              <VerificationCodeValidation />
            </Route>
            <Route>
              <Redirect from="*" to={route.welcome} />
            </Route>
          </Switch>
        </OnboardingDataProvider>
      </BrowserRouter>
    </>
  );
};

export default AppRouter;
