import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMediaQuery } from '@react-hook/media-query';
import { FormProvider, useForm } from 'react-hook-form';
import { Alert } from '@material-ui/lab';

import {
  Box, Grid, Typography, Button, Hidden, Checkbox, Link,
} from '@material-ui/core';

import { route } from '../constants/routes';
import { errorMessages } from '../constants/errorMessages';
import { verificationCodeSchemaValidation } from '../forms/schemaValidations/schemaValidations';
import TextInput from '../components/common/TextInput';
import LoadingButton from '../components/common/LoadingButton';
import NavBar from '../components/NavBar';
import HeaderLandingWelcome from '../components/HeaderLandingWelcome';
import { useOnboardingData } from '../contexts/onboardingDataContext';
import { saveCurrentOnboardingData } from '../utils/userStorage';
import { verifyCode, startWorkflow, sendCode } from '../api/ecommerceWebService';
import { ORIGINATION_SELFIE_URL, getEnv } from '../api/env';
import i18n from '../common/i18n';
import Loading from '../components/common/Loading';
import ErrorMessage from '../components/common/ErrorMessage';
import { fillMessageWith } from '../utils/functionsUtil';
import GoBackAndLogoDesktop from '../components/GoBackAndLogoDesktop';
import MaintenanceMessage from '../components/MaintenanceMessage';

const VerificationCodeValidation = () => {
  const methods = useForm({
    resolver: yupResolver(verificationCodeSchemaValidation),
  });

  const history = useHistory();
  const isMobile = useMediaQuery('(max-width:600px)');

  const [checkTerms, setCheckTerms] = useState(false);
  const [responseError, setResponseError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [callToAction, setCallToAction] = useState(undefined);
  const [allowReturnEcommerce, setAllowReturnEcommerce] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingInitOffer, setLoadingInitOffer] = useState(false);
  const [errorCode, setErrorCode] = useState(false);
  const [errorTerms, setErrorTerms] = useState(false);
  const [resendButtonClicked, setResendButtonClicked] = useState(false);
  const [showMaintenanceMessage, setShowMaintenanceMessage] = useState(undefined);

  const { currentOnboardingData, setCurrentOnboardingData } = useOnboardingData();

  const timeLimit = 60;
  const [timeLeft, setTimeLeft] = useState(timeLimit);
  const originationSelfieUrl = getEnv(ORIGINATION_SELFIE_URL);
  const transactionId = new URLSearchParams(window.location.search).get('trx-uuid');
  const onboardingLink = `${route.onboarding}?trx-uuid=${transactionId.toString()}`;

  const retry = () => {
    const trxUUID = new URLSearchParams({ 'trx-uuid': transactionId }).toString();
    history.push({ pathname: route.onboarding, search: `?${trxUUID}` });
  };

  const onSubmit = async (data) => {
    setErrorCode(false);
    setErrorTerms(false);
    const { customerTaxId } = currentOnboardingData.selectedUser;
    const { cellphone } = currentOnboardingData.contactData;
    let codeValidated = false;

    if (checkTerms) {
      setLoading(true);
      try {
        await verifyCode(customerTaxId, data.code, cellphone);
        codeValidated = true;
      } catch (error) {
        if (error && error.data && error.data.code === 'INVALID_SMS_CODE_ERROR') {
          setLoading(false);
          setErrorCode(true);
        } else {
          setResponseError(true);
        }
      }

      if (codeValidated) {
        try {
          setLoadingInitOffer(true);
          const response = await startWorkflow(customerTaxId, currentOnboardingData.contactData.cellphone,
            currentOnboardingData.shoppingCart.transactionId, currentOnboardingData.contactData.email);
          window.location.href = `${originationSelfieUrl}/m/${response.data.hashKey}`;
        } catch (error) {
          if (error?.data?.code === 'SERVICE_UNAVAILABLE_FOR_MAINTENANCE') {
            setShowMaintenanceMessage(true);
          } else if (error && error.data
              && error.data.code === 'THERE_IS_ANOTHER_BNPL_OFFER_FOR_DELIVERY_WITH_THE_SAME_CUIT') {
            setErrorMessage(errorMessages.ANOTHER_CART_IN_PROGRESS);
          } else {
            setErrorMessage(errorMessages.GET_OFFER);
            setCallToAction(() => () => retry());
          }
          setResponseError(true);
          setAllowReturnEcommerce(true);
        }
      }
    } else {
      setErrorTerms(true);
    }
  };

  const resendCode = async () => {
    try {
      const { customerTaxId } = currentOnboardingData.selectedUser;
      const { cellphone, email } = currentOnboardingData.contactData;
      const onboardingData = { ...currentOnboardingData };

      const resp = await sendCode(customerTaxId, null, true, email, cellphone);
      onboardingData.smsCode = resp.data.smsCode;

      setCurrentOnboardingData(onboardingData);
      saveCurrentOnboardingData(onboardingData);

      setTimeLeft(timeLimit);
      setResendButtonClicked(true);
    } catch (error) {
      setResponseError(true);
    }
  };

  const goToWelcome = () => {
    const trxUUID = new URLSearchParams({ 'trx-uuid': transactionId }).toString();
    history.push({ pathname: route.welcome, search: `?${trxUUID}` });
  };

  const changeCheck = (value) => {
    setCheckTerms(value);
    setErrorTerms(!value);
  };

  const init = async () => {
    if (transactionId === null) {
      setResponseError(true);
    } else if (currentOnboardingData === null || currentOnboardingData.shoppingCart === undefined) {
      goToWelcome();
    }
  };

  useEffect(() => init(), []);

  useEffect(() => {
    // Set to "true" when component is mounted
    let mounted = true;
    setTimeout(() => {
      // Check if the component is still mounted
      if (mounted) {
        setTimeLeft(timeLeft - 1);
      }
    }, 1000);
    // Set to "false" when the component is unmounted
    return () => {
      mounted = false;
    };
  }, [timeLeft]);

  return (
    <>
      {loadingInitOffer && responseError === false && !showMaintenanceMessage && (<Loading message={i18n.loadingMessageInitOffer} />)}

      {!loadingInitOffer && responseError === false && currentOnboardingData && !showMaintenanceMessage && (
        <>
          <Hidden mdUp>
            <NavBar shoppingCart={currentOnboardingData.shoppingCart} />
          </Hidden>
          <Grid container>
            <Hidden smDown>
              <Grid item xs={12} md={6}>
                <HeaderLandingWelcome />
              </Grid>
            </Hidden>
            <Grid item container xs={12} md={6} justifyContent="center" alignContent="center">
              <Grid item container xs={11} md={8}>
                <Hidden mdDown>
                  <Box m={1} />
                  <GoBackAndLogoDesktop shoppingCart={currentOnboardingData.shoppingCart} />
                  <Box m={2} />
                </Hidden>
                <Box m={isMobile ? 3 : 0} />
                <Grid item>
                  <Typography variant="h2" style={{ fontSize: '1.6rem' }}>{i18n.verificationCodeTitle}</Typography>
                  <Box m={1} />
                  <Typography variant="h5" style={{ fontSize: '1rem' }}>
                    {i18n.verificationCodeSubtitle}
                    {' '}
                    <b>{ currentOnboardingData.contactData.cellphone }</b>
                    {' '}
                    <Link href={onboardingLink} style={{ fontSize: '1rem', cursor: 'pointer' }}>
                      {i18n.verificationCodeNotYourCellphone}
                    </Link>
                  </Typography>
                </Grid>
                <Box m={2} />
                <Grid item container xs={12}>
                  <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                      <Grid item container>
                        <Grid item container xs={12}>
                          <TextInput
                            name="code"
                            label={i18n.verificationCodeValidationCodeLabel}
                            inputProps={{ minLength: 4, maxLength: 4 }}
                            type="number"
                            mandatory
                            fullWidth
                          />
                          {errorCode && (
                            <>
                              <Grid item xs={12}>
                                <Box m={3} />
                              </Grid>
                              <Grid item>
                                <Alert severity="error">{i18n.verificationCodeInvalidCode}</Alert>
                              </Grid>
                            </>
                          )}
                          {timeLeft < 1 ? (
                            <>
                              <Grid item container>
                                <Grid item>
                                  <Box m={2} />
                                  <Button onClick={resendCode}>
                                    {i18n.verificationCodeReSendCode}
                                  </Button>
                                </Grid>
                              </Grid>

                            </>
                          ) : (
                            <>
                              <Grid item xs={12}>
                                <Box m={3} />
                                <Typography>
                                  {fillMessageWith(i18n.verificationCodeCountDownMessage, [timeLeft])}
                                </Typography>
                                <Box m={3} />
                              </Grid>
                              {resendButtonClicked && currentOnboardingData.contactData.email && (
                                <>
                                  <Alert icon={false} severity="info">
                                    <div className="alert-email">
                                      {fillMessageWith(i18n.verificationCodeMessageToEmail,
                                        [currentOnboardingData.contactData.email])}
                                    </div>
                                  </Alert>
                                </>
                              )}
                              {currentOnboardingData.smsCode && (
                                <Alert>
                                  <Typography>
                                    Codigo sms (solo se muestra para pruebas!):
                                    {' '}
                                    {currentOnboardingData.smsCode}
                                  </Typography>
                                </Alert>
                              )}
                            </>
                          )}
                        </Grid>
                        <Box m={3} />
                        <Grid item container>
                          <Grid item style={{ display: 'flex' }}>
                            <Checkbox
                              color="primary"
                              style={{ margin: '7px' }}
                              onChange={() => { changeCheck(!checkTerms); }}
                            />

                            <Typography style={{ textAlign: 'left', fontSize: '0.8rem' }}>
                              {i18n.verificationCodeTerms1}
                              {' '}
                              <Link href="https://clientes.credicuotas.com.ar/#/terms" target="_blank"><b>{i18n.verificationCodeTerms2}</b></Link>
                              ,
                              {' '}
                              <Link href="https://clientes.credicuotas.com.ar/#/privacy" target="_blank"><b>{i18n.verificationCodeTerms3}</b></Link>
                              {' '}
                              {i18n.verificationCodeTerms4}
                            </Typography>
                          </Grid>
                          {errorTerms && (
                            <>
                              <Grid item xs={12}>
                                <Box m={3} />
                              </Grid>
                              <Grid item>
                                <Alert severity="error">{i18n.verificationCodeTermsValidation}</Alert>
                              </Grid>
                            </>
                          )}
                        </Grid>
                      </Grid>
                      <Box m={isMobile ? 3 : 4} />
                      <LoadingButton
                        variant="contained"
                        color="primary"
                        style={{ width: '100%' }}
                        type="submit"
                        loading={loading}
                      >
                        {i18n.verificationCodeCTAContinue}
                      </LoadingButton>
                    </form>
                  </FormProvider>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}

      {showMaintenanceMessage && (
        <MaintenanceMessage />
      )}

      {responseError && !showMaintenanceMessage && (
        <ErrorMessage message={errorMessage} callToAction={callToAction} allowReturnEcommerce={allowReturnEcommerce} />
      )}
    </>
  );
};

export default VerificationCodeValidation;
