import {
  Grid, Typography, makeStyles,
} from '@material-ui/core';
import i18n from '../common/i18n';
import bgImg from '../assets/imgs/pink-background.png';

const useStyles = makeStyles((theme) => ({
  grid: {
    backgroundColor: theme.palette.primary.main,
    backgroundImage: `url(${bgImg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100vh',
    '@media (max-width:600px)': {
      height: '240px',
    },
    '@media (max-width:414px)': {
      height: '270px',
    },
    '@media (max-width:380px)': {
      height: '260px',
    },
    '@media (max-width:359px)': {
      height: '300px',
    },
  },
  titleFont: {
    marginTop: '25vh',
    fontSize: '2.7rem',
    textAlign: 'left',
    lineHeight: '4rem',
    '@media (max-width:600px)': {
      marginTop: '0vh',
      fontSize: theme.typography.h1.fontSize,
      textAlign: 'center',
      lineHeight: '3rem',
    },
  },
  padding: {
    padding: '100px',
    '@media (max-width:900px)': {
      padding: '35px',
    },
  },
}));

function HeaderLandingWelcome() {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={12} className={classes.grid}>
        <Grid
          item
          className={classes.padding}
        >
          <Typography variant="h1" className={classes.titleFont}>{i18n.welcomeHeaderTitle}</Typography>
        </Grid>
      </Grid>
    </>
  );
}

export default HeaderLandingWelcome;
