import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import i18n from '../../common/i18n';
import '../../assets/css/loadingstyle.css';

const Loading = ({ message }) => (
  <>
    <Grid container style={{ height: '50vh' }} alignContent="center" justifyContent="center">
      <Grid item container xs={12} justifyContent="center">
        <Grid item container xs={12} justifyContent="center">
          <Grid item>
            <svg className="svg-cc" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90.5 91.7" style={{ enableBackground: 'new 0 0 90.5 91.7', textAlign: 'center' }} xmlSpace="preserve">
              <path
                className="square-cc"
                d={i18n.loadingSVG1}
                style={{
                  fill: 'none', stroke: '#ededed', strokeWidth: '10', strokeMiterlimit: '10',
                }}
              />
              <path
                className="square-cc"
                d={i18n.loadingSVG2}
                style={{
                  fill: '#e62566', stroke: '#FF6EA6', strokeWidth: '2', strokeMiterlimit: '10',
                }}
              />
              <path
                d={i18n.loadingSVG3}
                style={{ fill: '#fff' }}
              />
            </svg>
          </Grid>

        </Grid>
        <Grid item container xs={12} justifyContent="center">
          <Grid item>
            <Typography>
              {message}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </>
);

Loading.propTypes = {
  message: PropTypes.string,
};

Loading.defaultProps = {
  message: i18n.loadingMessage,
};

export default Loading;
