import Axios from 'axios';

export const instance = Axios.create({
  timeout: 50000,
  headers: { 'content-type': 'application/json;charset=UTF-8' },
});

const fetch = (config) => instance.request(config)
  .then((response) => response)
  .catch((error) => {
    throw error.response;
  });

export default fetch;
