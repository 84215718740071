import PropTypes from 'prop-types';
import {
  Card, CardActionArea, CardContent, Grid, makeStyles, Typography, CircularProgress,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    borderColor: theme.palette.primary.main,
    textTransform: 'none',
  },
  cardTitle: {
    fontFamily: 'Open sans',
    fontWeight: 700,
    textAlign: 'left',
    '@media (max-width:600px)': {
      fontSize: '1rem',
    },
  },
  cardSubtitle: {
    fontFamily: 'Open sans',
    fontWeight: 400,
    textAlign: 'left',
    fontSize: '1.2rem',
    color: '#737373',
    '@media (max-width:600px)': {
      fontSize: '1rem',
    },
  },
  button: {
    backgroundColor: 'white',
    color: 'black',
    '&:hover': {
      backgroundColor: '#ED8CB2',
      color: 'white',
    },
  },
}));

function CardButton({
  name, cuit, onClick, loading, disable,
}) {
  const classes = useStyles();

  return (
    <Card className={classes.card} variant="outlined">
      <CardActionArea onClick={onClick} disabled={disable}>
        <CardContent>
          <Grid container justifyContent="center">
            <Grid item xs={11}>
              <Typography variant="h5" component="h2" className={classes.cardTitle}>
                {name}
              </Typography>
              <Typography variant="h5" component="h2" className={classes.cardSubtitle}>
                {cuit}
              </Typography>
            </Grid>
            <Grid container xs={1} justifyContent="flex-end">
              {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
CardButton.propTypes = {
  name: PropTypes.string,
  cuit: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  disable: PropTypes.bool,
};
CardButton.defaultProps = {
  name: '',
  cuit: '',
  loading: false,
  disable: false,
};
export default CardButton;
