import i18n from '../common/i18n';
import { fillMessageWith } from '../utils/functionsUtil';

export const errorMessages = {
  CELLPHONE_VALIDATION: {
    title: i18n.errorMessageCellphone,
    secondaryCTALabel: i18n.errorMessageCellphoneCTA,
  },
  ANOTHER_CART_IN_PROGRESS: {
    title: i18n.errorMessageAnotherCartInProgress,
  },
  GET_OFFER: {
    secondaryCTALabel: i18n.errorMessageCTARetry,
  },
  INVALID_STATUS_CART(shoppingCartNumber) {
    return { title: fillMessageWith(i18n.errorMessageInvalidStatusCart, [shoppingCartNumber]) };
  },
};

export default errorMessages;
