import { Button, Grid, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PropTypes from 'prop-types';
import i18n from '../common/i18n';

function GoBackButton({ backUrl, callToAction, color }) {
  return (
    <>
      <Grid item xs={12} alignContent="center">
        <Button
          startIcon={<ArrowBackIcon fontSize="10px" style={{ color }} />}
          href={backUrl}
          onClick={callToAction}
        >
          <Typography style={{ color, fontSize: '12px' }}>{i18n.goBackButton}</Typography>
        </Button>
      </Grid>
    </>
  );
}
GoBackButton.propTypes = {
  backUrl: PropTypes.string,
  callToAction: PropTypes.func,
  color: PropTypes.string,
};
GoBackButton.defaultProps = {
  backUrl: '',
  callToAction: undefined,
  color: 'white',
};
export default GoBackButton;
