import {
  Grid, makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import logo from '../logo.svg';
import GoBackButton from './GoBackButton';

const useStyles = makeStyles({
  header: {
    backgroundColor: 'white',
    width: '100%',
    borderBottom: '1px solid #D6D6D6',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
});

const NavBar = ({ shoppingCart }) => {
  const classes = useStyles();

  return (
    <>
      {shoppingCart && (
      <Grid container className={classes.header} alignItems="center" justifyContent="center">
        <Grid item xs={6} style={{ textAlign: 'left', paddingLeft: '10px' }}>
          <GoBackButton
            style={{
              float: 'left',
              textAlign: 'left',
            }}
            color="primary"
            backUrl={shoppingCart.merchantReturnFailURL}
          />
        </Grid>
        <Grid item xs={6} alignContent="center">
          <img
            src={logo}
            className="logo-cc"
            alt="Logo Credicuotas"
            width="200px"
            style={{
              float: 'right',
              marginRight: '10px',
            }}
          />
        </Grid>
      </Grid>
      )}
    </>
  );
};
NavBar.propTypes = {
  shoppingCart: PropTypes.shape({
    merchantReturnFailURL: PropTypes.string.isRequired,
  }),
};
NavBar.defaultProps = {
  shoppingCart: undefined,
};
export default NavBar;
