import { Box, Grid, Typography } from '@material-ui/core';
import i18n from '../common/i18n';
import warningImg from '../assets/imgs/warning.svg';

const MaintenanceMessage = () => (
  <Box mt={5}>
    <Grid container>
      <Grid item xs={10} md={10}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <img src={warningImg} alt="calculando" width="150px" />
          <Box mt={3} />
          <Typography variant="h2" align="center">{i18n.maintenanceMessage}</Typography>
          <Box mt={2} />
          <Typography variant="h5" align="center">{i18n.maintenanceMessageTryLater}</Typography>
        </Box>
      </Grid>
    </Grid>
  </Box>
);

export default MaintenanceMessage;
