import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import { getEnv, GOOGLE_ANALYTICS_ID, GOOGLE_TAG_MANAGER_ID } from './api/env';
import './App.css';
import Theme from './assets/theme/theme';
import AppRouter from './navigation/AppRouter';

function App() {
  useEffect(() => {
    ReactGA.initialize(getEnv(GOOGLE_ANALYTICS_ID));
    TagManager.initialize({ gtmId: getEnv(GOOGLE_TAG_MANAGER_ID) });
  },
  []);

  return (
    <div className="App">
      <MuiThemeProvider theme={Theme}>
        <CssBaseline />
        <AppRouter />
      </MuiThemeProvider>
    </div>
  );
}

export default App;
