import PropTypes from 'prop-types';
import {
  Box, Button, Grid, Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router';
import { useOnboardingData } from '../../contexts/onboardingDataContext';
import imgError from '../../assets/imgs/error.svg';
import i18n from '../../common/i18n';
import GoBackButton from '../GoBackButton';

const ErrorMessage = ({ message, callToAction, allowReturnEcommerce }) => {
  const { currentOnboardingData } = useOnboardingData();
  const history = useHistory();

  const ctaReturnCommerce = () => {
    if (currentOnboardingData && currentOnboardingData.shoppingCart) {
      window.location.href = currentOnboardingData.shoppingCart.merchantReturnFailURL;
    } else {
      history.go(-1);
    }
  };

  return (
    <>
      <Box m={3} />
      <Grid container justify="center">
        <Grid item xs={10} md={10} style={{ textAlign: 'center' }}>
          <img src={imgError} className="error" width="130px" alt="" />
          <Box m={3} />
          <Box m={3} />
          <Typography variant="h5">
            {message && message.title ? message.title : i18n.errorMessageTitle}
          </Typography>
        </Grid>
      </Grid>
      <Box m={6} />
      <Grid container justify="center">
        <Grid item xs={10} sm={8} md={6} style={{ textAlign: 'center' }}>
          {callToAction && (
            <>
              <Button variant="outlined" color="primary" onClick={callToAction} fullWidth disableRipple>
                {message.secondaryCTALabel}
              </Button>
              <Box m={3} />
            </>
          )}
          {allowReturnEcommerce && (
            <GoBackButton callToAction={ctaReturnCommerce} color="primary" />
          )}
        </Grid>
      </Grid>
    </>
  );
};

ErrorMessage.propTypes = {
  message: PropTypes.shape({
    title: PropTypes.string.isRequired,
    secondaryCTALabel: PropTypes.string.isRequired,
  }),
  callToAction: PropTypes.func,
  allowReturnEcommerce: PropTypes.bool,
};
ErrorMessage.defaultProps = {
  message: undefined,
  callToAction: undefined,
  allowReturnEcommerce: true,
};

export default ErrorMessage;
