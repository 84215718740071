import * as yup from 'yup';
import i18n from '../../common/i18n';

export const onboardingSchemaValidation = yup.object().shape({
  dni: yup.string().required(i18n.onboardingSchemaValidationDNIRequired).nullable().matches(/^[0-9]{6,8}$/,
    { excludeEmptyString: true, message: i18n.onboardingSchemaValidationDNI6to8Digits }),
  email: yup.string().required(i18n.onboardingSchemaValidationEmailRequired).nullable()
    .email(i18n.onboardingSchemaValidationEmailInvalidFormat),
  cellphone: yup.string().required(i18n.onboardingSchemaValidationCellphoneRequired).nullable()
    .matches(/^[0-9]+$/, i18n.onboardingSchemaValidationCellphoneInvalidFormat),
});

export const verificationCodeSchemaValidation = yup.object().shape({
  code: yup.string().required(i18n.verificationCodeSchemaValidationCodeRequired).matches(/^[0-9]{4,4}$/,
    { excludeEmptyString: true, message: i18n.verificationCodeSchemaValidationCode4Digits }),
});
