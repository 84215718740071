/* eslint-disable no-template-curly-in-string */
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import {
  List, ListItem, Box, Grid, Typography, Hidden,
} from '@material-ui/core';

import CardUserConfirmation from '../components/CardUserConfirmation';
import { fillMessageWith } from '../utils/functionsUtil';
import { useOnboardingData } from '../contexts/onboardingDataContext';
import { saveCurrentOnboardingData } from '../utils/userStorage';
import { route } from '../constants/routes';
import { validateCellphone, updateCustomerData, sendCode } from '../api/ecommerceWebService';
import i18n from '../common/i18n';
import ErrorMessage from '../components/common/ErrorMessage';
import { errorMessages } from '../constants/errorMessages';
import NavBar from '../components/NavBar';
import HeaderLandingWelcome from '../components/HeaderLandingWelcome';
import GoBackAndLogoDesktop from '../components/GoBackAndLogoDesktop';

const CuilValidation = () => {
  const history = useHistory();

  const [responseError, setResponseError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [callToAction, setCallToAction] = useState(undefined);
  const [allowReturnEcommerce, setAllowReturnEcommerce] = useState(true);

  const { currentOnboardingData, setCurrentOnboardingData } = useOnboardingData();

  const transactionId = new URLSearchParams(window.location.search).get('trx-uuid');

  const retry = () => {
    const trxUUID = new URLSearchParams({ 'trx-uuid': transactionId }).toString();
    history.push({ pathname: route.onboarding, search: `?${trxUUID}` });
  };

  const goToSmsValidation = async (user, index) => {
    setLoading(true);
    setSelectedIndex(index);
    const onboardingData = { ...currentOnboardingData };
    onboardingData.selectedUser = user;

    const { customerTaxId } = user;
    const { cellphone, email } = onboardingData.contactData;

    try {
      await validateCellphone(customerTaxId, cellphone);
      await updateCustomerData(customerTaxId, cellphone, email);

      const resp = await sendCode(customerTaxId, null, false, email, cellphone);
      onboardingData.smsCode = resp.data.smsCode;

      setCurrentOnboardingData(onboardingData);
      saveCurrentOnboardingData(onboardingData);

      const trxUUID = new URLSearchParams({ 'trx-uuid': transactionId }).toString();
      history.push({ pathname: route.verificationCode, search: `?${trxUUID}` });
    } catch (error) {
      if (error && error.data && error.data.code === 'CELL_NUMBER_NOT_AVAILABLE') {
        setErrorMessage(errorMessages.CELLPHONE_VALIDATION);
        setCallToAction(() => () => retry());
        setResponseError(true);
        setAllowReturnEcommerce(false);
      } else {
        setResponseError(true);
      }
    }
  };

  const goBack = () => {
    const trxUUID = new URLSearchParams({ 'trx-uuid': transactionId }).toString();
    history.push({ pathname: route.onboarding, search: `?${trxUUID}` });
  };

  const goToWelcome = () => {
    const trxUUID = new URLSearchParams({ 'trx-uuid': transactionId }).toString();
    history.push({ pathname: route.welcome, search: `?${trxUUID}` });
  };

  const init = async () => {
    if (transactionId === null) {
      setResponseError(true);
    } else if (currentOnboardingData === null || currentOnboardingData.shoppingCart === undefined) {
      goToWelcome();
    }
  };

  useEffect(() => init(), []);

  return (
    <>
      {responseError === false && currentOnboardingData && (
        <>
          <Hidden mdUp>
            <NavBar shoppingCart={currentOnboardingData.shoppingCart} />
          </Hidden>
          <Grid container justifyContent="center">
            <Hidden mdDown>
              <Grid item xs={12} md={6}>
                <HeaderLandingWelcome />
              </Grid>
            </Hidden>
            <Grid item container xs={12} md={6} justifyContent="center">
              <Grid item xs={11} md={8}>
                <Hidden mdDown>
                  <Box m={5} />
                  <GoBackAndLogoDesktop shoppingCart={currentOnboardingData.shoppingCart} />
                  <Box m={5} />
                </Hidden>
                <Hidden mdUp>
                  <Box m={5} />
                </Hidden>
                <Typography variant="h2">{i18n.cuilValidationTitle}</Typography>
                <Box m={3} />
                <List>
                  {currentOnboardingData && currentOnboardingData.users.map((user, index) => (
                    <ListItem key={user.customerTaxId} disableGutters>
                      <CardUserConfirmation
                        name={user.fullname}
                        cuit={fillMessageWith('CUIT: ${0}', [user.customerTaxId])}
                        onClick={() => { goToSmsValidation(user, index); }}
                        loading={loading && index === selectedIndex}
                        disable={loading}
                      />
                    </ListItem>
                  ))}
                  <ListItem disableGutters>
                    <CardUserConfirmation
                      name={i18n.cuilValidationNone}
                      onClick={goBack}
                      disable={loading}
                    />
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}

      {responseError && (
        <ErrorMessage message={errorMessage} callToAction={callToAction} allowReturnEcommerce={allowReturnEcommerce} />
      )}
    </>
  );
};

export default CuilValidation;
