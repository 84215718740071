import {
  Box, Button, Grid, Typography, makeStyles, Hidden,
} from '@material-ui/core';
import { useHistory } from 'react-router';
import { useEffect, useState } from 'react';
import BodyLandingWelcome from '../components/BodyLandingWelcome';
import { route } from '../constants/routes';
import i18n from '../common/i18n';
import Loading from '../components/common/Loading';
import { getShoppingCart } from '../api/ecommerceWebService';
import { useOnboardingData } from '../contexts/onboardingDataContext';
import ErrorMessage from '../components/common/ErrorMessage';
import { errorMessages } from '../constants/errorMessages';
import NavBar from '../components/NavBar';
import HeaderLandingWelcome from '../components/HeaderLandingWelcome';
import GoBackAndLogoDesktop from '../components/GoBackAndLogoDesktop';

const useStyles = makeStyles({
  negativeMargin: {
    marginTop: '0px',
    '@media (max-width:600px)': {
      marginTop: '-100px',
    },
    '@media (max-width:414px)': {
      marginTop: '-100px',
    },
    '@media (max-width:380px)': {
      marginTop: '-100px',
    },
  },
});

const EcommerceLandingWelcome = () => {
  const history = useHistory();
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [responseError, setResponseError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);

  const [shoppingCartDto, setShoppingCartDto] = useState([]);
  const { setCurrentOnboardingData } = useOnboardingData();

  const s3Folder = 'https://cc-ecommerce-assets.s3.sa-east-1.amazonaws.com';
  const transactionId = new URLSearchParams(window.location.search).get('trx-uuid');

  const goToOnboarding = () => {
    const trxUUID = new URLSearchParams({ 'trx-uuid': transactionId }).toString();
    history.push({
      pathname: route.onboarding,
      search: `?${trxUUID}`,
      state: { cart: shoppingCartDto },
    });
  };

  const initShoppingCart = async () => {
    try {
      const response = await getShoppingCart(transactionId);
      const dto = response.data;
      dto.imgLogo = `${s3Folder}/${dto.merchantId.toLowerCase()}-logo.png`;
      setShoppingCartDto(dto);
      setCurrentOnboardingData({ shoppingCart: dto });
      setLoading(false);
    } catch (error) {
      if (error && error.data
          && error.data.code === 'RESOLVE_CART_INVALID_STATUS') {
        setErrorMessage(errorMessages.INVALID_STATUS_CART(transactionId));
      }
      setLoading(false);
      setResponseError(true);
    }
  };

  useEffect(() => initShoppingCart(), []);

  return (
    <>
      {loading && (<Loading />)}

      {loading === false && responseError === false && shoppingCartDto && (
        <>
          <Hidden smUp>
            <NavBar shoppingCart={shoppingCartDto} />
          </Hidden>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <HeaderLandingWelcome />
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={6}
              justifyContent="center"
              alignItems="center"
              className={classes.negativeMargin}
            >
              <Grid item container sm={9} xs={11}>
                <Hidden xsDown>
                  <GoBackAndLogoDesktop shoppingCart={shoppingCartDto} />
                </Hidden>
                <Box m={3} />
                <BodyLandingWelcome shoppingCart={shoppingCartDto} />
                <Box m={3} />
                <Typography>{i18n.welcomeWording}</Typography>
                <Box m={1} />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={goToOnboarding}
                  fullWidth
                  style={{ marginTop: '30px' }}
                >
                  {i18n.welcomeCTABuyNow}
                </Button>
                <Box m={3} />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}

      {responseError && (<ErrorMessage message={errorMessage} />)}
    </>
  );
};
export default EcommerceLandingWelcome;
