export const moneyFormatter = new Intl.NumberFormat('es-AR', {
  style: 'currency',
  currency: 'ARS',
  minimumFractionDigits: 2,
});

export const dateFormatter = (date) => {
  const options = {
    timeZone: 'UTC',
  };
  const formatter = new Intl.DateTimeFormat('es-AR', options);
  return formatter.format(new Date(date));
};
