/* eslint-disable no-template-curly-in-string */
/* eslint-disable max-len */
const i18n = {

  // Welcome
  welcomeCTABuyNow: 'Continuar',

  // Welcome header
  welcomeHeaderTitle: '¡Pagá en cuotas fijas con Credicuotas!',
  welcomeWording: 'Te ofrecemos un préstamo personal para financiar tu compra y devolverlo en cuotas fijas y en pesos. Solo necesitás tu DNI, una selfie y tu tarjeta de débito.',

  // Welcome body
  welcomeBodyHowItWorks: '¿Cómo funciona?',
  welcomeBodyDescription: 'Con Credicuotas podrás comprar tu producto en cuotas fijas y en pesos, solo con tu DNI.',
  welcomeBodySimulateFinanciation: 'Simulá tu financiación.',
  welcomeBodyChooseTerms: 'Elegí el plazo de pago y carga tus datos.',
  welcomeBodySign: 'Firma la solicitud y listo, volvé a la tienda!',

  // Go back button
  goBackButton: 'Volver a la tienda',

  // Go back link
  verificationCodeNotYourCellphone: '¿No es tu celular?',

  // Card shopping cart
  cardShoppingCartTitle: 'Tu orden de compra ',
  cardShoppingCartTitle2: ' en',

  // Onboarding
  onboardingTitle: 'Empecemos',
  onboardingSubitle: 'Todos los datos son obligatorios',
  onboardingDNILabel: 'DNI',
  onboardingDNIHint: 'Sin espacios ni puntos',
  onboardingEmailLabel: 'E-mail',
  onboardingCellphoneLabel: 'Teléfono celular',
  onboardingCellPhoneHint: 'Omití el 0 del código de área y el 15 del número de celular. Ejemplo: 1132222222',
  onboardingCTAContinue: 'Continuar',
  onboardingSchemaValidationDNI6to8Digits: 'El DNI debe ser un número entre 6 y 8 dígitos',
  onboardingSchemaValidationDNIRequired: 'El DNI es requerido',
  onboardingSchemaValidationEmailInvalidFormat: 'El formato del email es incorrecto',
  onboardingSchemaValidationEmailRequired: 'El email es requerido',
  onboardingSchemaValidationCellphoneRequired: 'El teléfono celular es requerido',
  onboardingSchemaValidationCellphoneInvalidFormat: 'El formato del teléfono es incorrecto. Por favor omití el 0 del código de área y el 15 del número de celular. Ejemplo: 1132222222',
  onboardingBuying: 'Estas comprando en ',
  onboardingPrice: 'por ',

  // Cuil validation
  cuilValidationTitle: 'Confirmá tu nombre',
  cuilValidationNone: 'Ninguno de los anteriores',

  // Sms code verification
  verificationCodeTitle: 'Validá tu celular',
  verificationCodeSubtitle: 'Ingresá el código de validación de 4 dígitos que recibiste por SMS al ',
  verificationCodeValidationCodeLabel: 'Código de validación',
  verificationCodeCTAContinue: 'Continuar',
  verificationCodeSchemaValidationCode4Digits: 'El Código debe ser un número de 4 dígitos',
  verificationCodeSchemaValidationCodeRequired: 'El Código es requerido',
  verificationCodeInvalidCode: 'El código es incorrecto',
  verificationCodeCountDownMessage: '¿Todavía no recibiste el código? Vas a poder reenviarlo en ${0} segundos',
  verificationCodeMessageToEmail: 'Código reenviado. Si no te llega el SMS, revisá en tu correo electrónico (${0})',
  verificationCodeReSendCode: 'Reenviar el código',
  verificationCodeTerms1: 'Acepto los',
  verificationCodeTerms2: 'Términos y Condiciones',
  verificationCodeTerms3: 'Política de Privacidad',
  verificationCodeTerms4: 'y gestionar mi Atención al Cliente vía Whatsapp para Asistencia de Ventas, Atención al Cliente y Cobranzas.',
  verificationCodeTermsValidation: 'Tenés que aceptar los Términos y Condiciones, y Política de Privacidad para poder continuar.',

  maintenanceMessage: 'En estos momentos estamos realizando tareas de mantenimiento.',
  maintenanceMessageTryLater: 'Por favor volvé a intentar en una hora.',

  // Error message
  errorMessageTitle: 'Ups! tuvimos unos problemas con tu solicitud, por favor volve a reintentar en unos minutos.',
  errorMessageCTARetry: 'Volver a empezar',
  errorMessageCTAReturnCommerce: 'Volver a la tienda',
  errorMessageCellphone: 'El número de celular ingresado ya esta registrado con otro usuario.',
  errorMessageCellphoneCTA: 'Reintentar',
  errorMessageInvalidStatusCart: 'Este carrito de compra ${0} ya no está disponible',
  errorMessageAnotherCartInProgress: 'Tenés una orden de compra pendiente de entregar',

  // loading svg
  loadingMessage: 'Espere por favor...',
  loadingMessageInitOffer: 'Por favor esperá unos segundos, estamos buscando la mejor oferta para vos ☺',
  loadingSVG1: 'M61.5 69.5H29c-4.1 0-7.4-3.3-7.4-7.4V29.7c0-4.1 3.3-7.4 7.4-7.4h32.4c4.1 0 7.4 3.3 7.4 7.4v32.4c.1 4.1-3.2 7.4-7.3 7.4z',
  loadingSVG2: 'M61.5 69.5H29c-4.1 0-7.4-3.3-7.4-7.4V29.7c0-4.1 3.3-7.4 7.4-7.4h32.4c4.1 0 7.4 3.3 7.4 7.4v32.4c.1 4.1-3.2 7.4-7.3 7.4z',
  loadingSVG3: 'M55.2 46.9c-.4-.8-.9-1.4-1.6-1.9-.7-.5-1.5-.9-2.3-1.2-.9-.3-1.8-.5-2.7-.6l-3.8-.5c-.9-.1-1.6-.3-2-.5-.4-.2-.7-.5-.7-1s.2-.9.7-1.2c.5-.3 1.4-.4 2.6-.4 1.1 0 2.1.1 3 .3.9.2 1.6.4 2.3.6.6.2 1.1.4 1.5.6.4.2.6.4.7.5.1 0 .2-.1.4-.3.2-.1.4-.3.7-.6l.6-.9c.2-.3.3-.7.3-1.2 0-.6-.2-1.1-.5-1.5-.3-.4-.7-.7-1.3-1-.9-.5-2-.8-3.3-1-.4-.1-.8-.1-1.3-.2V34c0-.9-.8-1.7-1.7-1.7h-3c-.9 0-1.7.8-1.7 1.7v1c-.5.1-.9.2-1.3.3-1.3.4-2.3.9-3.1 1.5-.8.7-1.4 1.4-1.8 2.3-.4.9-.6 1.8-.6 2.9 0 1.2.2 2.2.7 3.1.4.8 1 1.5 1.8 2s1.7.9 2.7 1.2c1 .3 2 .5 3.1.6l2.2.2c1 .1 1.8.3 2.3.5.5.2.7.6.7 1.2 0 .5-.3.9-.9 1.1-.6.2-1.5.3-2.6.3-1 0-2-.1-2.9-.2-.9-.1-1.7-.3-2.4-.5s-1.3-.4-1.8-.7c-.5-.2-.9-.4-1.1-.6l-2.4 4.3c.2.2.6.4 1.2.7.6.3 1.3.6 2.2.9.9.3 2 .5 3.3.8.2 0 .4.1.7.1v.9c0 .9.8 1.7 1.7 1.7h3c.9 0 1.7-.8 1.7-1.7V57c1.9-.3 3.5-.9 4.7-1.8 1.8-1.3 2.7-3.1 2.7-5.5 0-1.1-.3-2.1-.7-2.8z',
};

export default i18n;
